import React, { createContext, useState } from 'react';

export const PricingToggleContext = createContext(true);

export const PricingToggleProvider = ({ children }) => {
  const [isAnnualPlan, setIsAnnualPlan] = useState(true);

  return (
    <PricingToggleContext.Provider value={{ isAnnualPlan, setIsAnnualPlan }}>
      {children}
    </PricingToggleContext.Provider>
  );
};
