// import { captureException } from '@sentry/gatsby';
import React, { createContext, useReducer } from 'react';

const initialState = {
  search: '',
  count: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_SEARCH':
      return { ...state, search: action.payload };
    case 'SET_COUNT':
      return { ...state, count: action.payload };
    default:
      console.log('FAQ Search Failure', state, action);
  }
};

export const FaqSearchContext = createContext({
  state: initialState,
  dispatch: null,
});

export const FaqSearchProvider = ({ children }) => {
  const [faqSearchState, faqSearchDispatch] = useReducer(reducer, initialState);

  return (
    <FaqSearchContext.Provider value={{ faqSearchState, faqSearchDispatch }}>
      {children}
    </FaqSearchContext.Provider>
  );
};
