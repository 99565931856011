import { captureMessage } from '@sentry/gatsby';
import React, { useState } from 'react';

export const DisclaimerContext = React.createContext(false);

export const disclaimerReducer = action => {
  if (typeof action !== 'boolean') {
    captureMessage('unusable value used in Disclaimer Reducer: ', action);
    return false;
  }
  return action;
};

export const DisclaimerProvider = ({ children }) => {
  const [isDisclaimerShown, setIsDisclaimerShown] = useState(false);
  return (
    <DisclaimerContext.Provider
      value={{ isDisclaimerShown, setIsDisclaimerShown }}
    >
      {children}
    </DisclaimerContext.Provider>
  );
};
