import React, { createContext, useReducer } from 'react';
// import { captureException } from '@sentry/gatsby';

const initialState = {
  open: false,
  reference: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_MODAL':
      return { open: true, reference: action.payload };
    case 'CLOSE_MODAL':
      return { ...initialState };
    default:
      console.log('Modal Failure', state, action);
  }
};

export const ModalContext = createContext({
  state: initialState,
  dispatch: null,
});

export const ModalProvider = ({ children }) => {
  const [modalState, modalDispatch] = useReducer(reducer, initialState);

  return (
    <ModalContext.Provider value={{ modalState, modalDispatch }}>
      {children}
    </ModalContext.Provider>
  );
};
